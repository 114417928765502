<div class="container">
  <p style="text-align: center; font-size: 26px">Joining game...</p>
  <div *ngIf="!error">
    <div class="loader"></div>
  </div>
  <div *ngIf="error">
    <p>{{error}}</p>
    <button
      mat-raised-button
      class="block-button"
      (click)="tryConnect()">Try again</button>
  </div>
</div>