<div style="max-width: 800px; display: block; margin: auto; position: relative">
  <div class="heading-container">
    <div style="margin:auto; width: auto; margin-bottom: 20px; margin-top: 10px">
      <a class="nostyle" href="/"><span *ngFor="let letter of myTitle" class="square">{{letter}}</span></a>
      <a [href]="link">v{{version}}</a>
    </div>
  </div>

  <div style="position: relative" [@fadeAnimation]="getRouterOutletState(o)">
    <router-outlet #o="outlet"></router-outlet>
  </div>
</div>